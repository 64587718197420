@mixin small-breakpoint {
	@media (min-width: #{$tablet}) {
		@content;
	}
}

@mixin large-breakpoint {
	@media (min-width: #{$desktop}) {
		@content;
	}
}


@mixin with-flex($direction: row, $items: flex-start, $content: center) {
	display: flex;
	flex-direction: $direction;
	align-items: $items;
	justify-content: $content;
}