.grid-2 {
  display: grid;
  grid-gap: 25px;

  @include large-breakpoint {
    grid-template-columns: repeat(2, 1fr);
  }
}

.layout {
  width: 100%;
  min-height: 100vh;
  @include with-flex(
    $direction: column,
    $content: flex-start,
    $items: flex-start
  );

  .layout__header-section {
    width: 100%;
    padding: 1.2rem 1.2rem 0;
    background-color: transparent;
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
  }

  .layout__content-section {
    flex: 1;
    width: 100%;
    position: relative;
  }

  .layout__header-section,
  .layout__footer-section {
    width: 100%;
  }

  @include large-breakpoint {
    .layout__header-section {
      padding: 3rem 4rem 0;
    }
  }
}
