.control {
  min-height: 2.8rem;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 2px solid $text-light;
  outline: none;
  font-family: inherit;
  font-weight: 500;
  transition: outline 0.3s ease;
  resize: none;
  font-size: 1rem;

  &:focus {
    outline: 3px solid $primary;
    border: none;
  }

  &:disabled {
    color: $text-light;
    background: #dedede;
    border: none;
    cursor: not-allowed;
  }

  &.invalid {
    color: $danger;
    border-color: $danger;
    outline-color: $danger;
  }
}

.form-control {
  &:not(:last-of-type) {
    margin: 0 0 10px;
  }

  label {
    display: block;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.6rem;
  }

  label.invalid {
    color: $danger;
  }

  .control {
    width: 100%;
  }

  .error {
    font-size: 1rem;
    line-height: 1rem;
    font-family: inherit;
    font-weight: 500;
    color: $danger;
    display: block;
    margin: 0.6rem 0;
  }
}
