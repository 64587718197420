.ml-1 {
	margin-left: 1rem;
}

.mt-1 {
	margin-top: 1rem;
}

.mb-1 {
	margin-bottom: 1rem;
}

.lg-only {
	display: none;
}

.space-grotesk {
	font-family: 'Space Grotesk', -apple-system, BlinkMacSystemFont, 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
}

.lg-text {
	font-weight: 700;
	font-size: 50px;

	@include large-breakpoint {
		font-size: 80px;
	}
}

.text-primary  {
	color: $primary;
}

.xl-text {
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 50px;
	letter-spacing: -0.015em;

	@include large-breakpoint {
		font-size: 52.04px;
		line-height: 70px;
		letter-spacing: -0.015em;
	}
}