* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;

  :target {
    scroll-margin-top: 120px;
  }
}

body {
  font-family: 'Segoe UI', 'Space Grotesk', -apple-system, BlinkMacSystemFont,
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .__toastify {
    font-family: 'Segoe UI', 'Space Grotesk', -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  .rc-tabs {
    max-width: 100%;
    border: none;
    font-family: inherit;

    .rc-tabs-tab {
      width: fit-content;
      margin-right: 30px;
      font-style: normal;
      font-weight: 300;
      font-size: 19.2px;
      line-height: 36px;
      letter-spacing: -0.015em;
      color: $primary;
      padding-bottom: 0.6rem;
    }

    .rc-tabs-tab-active {
      font-weight: 600;
    }

    .rc-tabs-ink-bar {
      background: $primary;
    }

    .rc-tabs-nav-list {
      flex-wrap: wrap;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
