.nav {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	padding: 15px 5px;
	background-color: $white;
	z-index: 4;

	.nav__content {
		width: 100%;
		max-width: 109.2rem;
		margin: 0 auto;
		padding: 0 20px 0 0;

		@include with-flex($items: center, $content: space-between);

		.nav-logo {
			max-height: 50px;
		}

		.btn-nav {
			margin-right: 30px;
		}

    .nav-sm-toggle {
      color: $primary;
    }
	}

	@include large-breakpoint {
		padding: 50px 0 25px;

		.nav__content {
			padding: 0 65px;

			@include with-flex(
				$direction: row,
				$items: center,
				$content: space-between
			);

			.nav-logo {
				max-height: 85px;
			}

			.lg-only {
				display: flex;
			}

			.sm-only {
				display: none;
			}

			.nav__content-top,
			.nav__content-bottom {
				@include with-flex(
					$direction: row,
					$items: center,
					$content: space-between
				);
			}
		}
	}
}
