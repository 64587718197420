.footer {
	width: 100%;
	max-width: 109.2rem;
	margin: 0 auto;
	padding: 15px 20px;
	background-color: $primary;
	font-family: inherit;

	.footer__title {
		font-style: normal;
		font-weight: 700;
		font-size: 1.5rem;
		line-height: 32px;
		color: $white;
		letter-spacing: -0.015em;
		margin-bottom: 2rem;
	}

	.footer__link {
		margin-bottom: 1.5rem;
		text-decoration: none;
		font-style: normal;
		font-weight: 400;
		font-size: 1.2rem;
		line-height: 24px;
		color: $white;
		letter-spacing: -0.015em;
		@include with-flex($content: flex-start, $items: center);
	}

	.footer__content {
		width: 100%;
		display: grid;
		grid-template-areas:
			'address address'
			'products support'
			'company company'
			'copyright copyright';

		.address {
			grid-area: address;
		}

		.products {
			grid-area: products;
		}

		.support {
			grid-area: support;
		}

		.company {
			grid-area: company;
		}

		.copyright {
			grid-area: copyright;
		}

		.address,
		.company,
		.products,
		.support {
			margin-top: 1rem;
			@include with-flex(
				$direction: column,
				$content: flex-start,
				$items: flex-start
			);
		}

		.socials {
			margin: 35px 0;
			width: 100%;
			@include with-flex(
				$direction: row,
				$content: flex-start,
				$items: center
			);

			.socials__link {
				text-decoration: none;
				margin-right: 1.5rem;
				color: #fff;

				img {
					width: 25px;
					height: 25px;
				}
			}
		}
	}

	@include small-breakpoint {
		.footer__content {
			grid-template-areas: 'products support' 'company address' 'copyright copyright';
		}
	}

	@include large-breakpoint {
		padding: 50px 100px;

		.footer__content {
			grid-template-areas:
				'address company products support'
				'copyright copyright copyright copyright';
		}
	}
}
