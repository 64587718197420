@font-face {
  font-family: 'Segoe UI';
  src: url('../assets/fonts/segoe-ui/Segoe\ UI.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../assets/fonts/segoe-ui/Segoe\ UI\ Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}