.get-started {
  width: 100%;
  max-width: 109.2rem;
  margin: 100px auto 0;

  padding: 0;

  .intro-text {
    width: 100%;
    color: $text-light;
    font-size: 20px;
    font-weight: 400;
  }

  .xl-text {
    font-size: 35px;
    line-height: 40px;
  }

  .form-content {
    width: 100%;
    max-width: 500px;
    padding-top: 1rem;
    .form-control {
      margin-bottom: 1rem;
    }
  }

  .get-started__content {
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  @include small-breakpoint {
    .get-started__content {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include large-breakpoint {
    margin: 150px auto 0;

    .get-started__content {
      padding: 100px 100px;
    }
  }
}
