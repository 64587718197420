.btn {
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
  height: 50px;
  width: 150px;
  border-radius: 5px;
  @include with-flex($content: center, $items: center);
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;

  &:active {
    transform: scale(0.9);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.btn-lg {
  width: 230px;
  height: 60px;
  max-width: 100%;
}

.btn-xl {
  width: 300px;
  height: 82.35px;
  max-width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;

  @include large-breakpoint {
    width: 565px;
    font-size: 33.1455px;
    line-height: 49px;
  }
}

.btn-outline-primary {
  color: $primary;

  &:hover {
    background-color: $primary-light;
  }
}

.btn-nav {
  @include with-flex($content: flex-start, $items: center);
  color: $primary;
  width: auto;
  font-weight: 400;

  &:hover {
    color: $secondary;
  }
}

.btn-primary {
  color: $white;
  background-color: $primary;
}

.header-btn {
  font-family: $space-grotesk;
  width: 200px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.btn-success {
  background-color: $success;
  color: $white;
}

.btn-primary-light {
  background: $primary-light;
  color: $primary;
}

.btn-secondary {
  color: $white;
  background-color: $secondary;
}

.btn-sm {
  width: 181.37px;
  height: 42px;
  font-style: normal;
  font-weight: 600;
  font-size: 19.2px;
  line-height: 36px;
  letter-spacing: -0.015em;
}
