$white: #fff;
$primary: #253e84;
$primary-light: rgba(37, 62, 132, 0.12);
$secondary: #faa220;
$success: #1cd348;
$success-light: #76eba5;
$alternate: #0fadbe;
$danger: #ff3131;
$text: #000000;
$text-light: #787878;
