.landing-page {
	width: 100%;
	max-width: 109.2rem;
	margin: 100px auto 0;

	padding: 0;

	.landing-page__intro {
		padding: 20px;
		margin-top: 4em;
		width: 100%;
		padding: 20px;

		display: grid;
		gap: 80px;
		grid-template-areas: 'one' 'two';

		.landing-page__intro-img {
			display: none;
			img {
				max-width: 100%;
				border-radius: 30px;
				grid-area: two;
			}
		}

		.landing-page__intro-info {
			grid-area: one;
			.intro-title {
				width: 100%;
				color: $primary;
			}

			.intro-text {
				width: 100%;
				color: $text-light;
				font-size: 24px;
				font-weight: 400;
			}

			.intro__actions {
				margin-top: 2rem;
				.btn {
					width: 288px;
				}
				.intro__downloads {
					margin-top: 24px;
					@include with-flex($content: flex-start);
	
					a {
						margin-right: 1rem;
						text-decoration: none;
						display: block;
					}
				}
			}

		}
	}

	.landing-page__what-we-do {
		margin-top: 4em;
		width: 100%;
		padding: 20px;

		.what-we-do-grid {
			width: 100%;
			margin-top: 45px;
			display: grid;
			grid-gap: 50px;

			.what-we-do-grid__img {
				width: 100%;
				height: 300px;
				border-radius: 0 30px 30px 30px;
				object-fit: cover;
			}

			.what-we-do-grid__data {
				width: 100%;
				display: grid;
				grid-gap: 30px;

				.data-item {
					@include with-flex(
						$direction: column,
						$items: flex-start,
						$content: flex-start
					);

					.data-item__img {
						margin-bottom: 12px;
					}

					.data-item__title {
						margin-bottom: 20px;
						font-style: normal;
						font-weight: 700;
						font-size: 28.4452px;
						line-height: 36px;
						display: flex;
						align-items: center;
						letter-spacing: -0.02em;
						color: $primary;
					}

					.data-item__text {
						font-style: normal;
						font-weight: 400;
						font-size: 21.4232px;
						line-height: 33px;
						letter-spacing: -0.015em;
						color: $text-light;
					}
				}
			}
		}
	}

	.landing-page__why-leadremit {
		margin-top: 4em;
		width: 100%;
		padding: 20px;

		.why-leadremit-grid {
			width: 100%;
			margin-top: 45px;
			display: grid;
			grid-gap: 50px;

			grid-template-areas: 'one' 'two';

			.why-leadremit-grid-img {
				img {
					width: 300px;
					max-width: 100%;
					border-radius: 30px;
				}

				grid-area: one;
			}

			.why-leadremit-grid-data {
				.why-leadremit-text {
					font-style: normal;
					font-weight: 400;
					font-size: 24px;
					line-height: 44px;
					letter-spacing: -0.015em;
					margin-bottom: 2rem;
				}

				grid-area: two;

				.why-leadremit-header {
					margin: 2rem 0;
					font-style: normal;
					font-weight: 700;
					font-size: 44px;
					line-height: 64px;
					color: $primary;
					letter-spacing: -0.015em;
				}
			}
		}
	}

	.landing-page__leadVoucher {
		width: 100%;
		background-color: #f5f5f5;
		padding: 50px 20px;
		display: grid;
		grid-gap: 30px;
		position: relative;

		.blue-vector {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			height: 445px;
			display: none;
		}

		.landing-page__leadvoucher-banner {
			max-width: 100%;
			object-fit: cover;
			border-radius: 30px;
			position: relative;
			z-index: 2;
		}

		.landing-page__leadvoucher-data {
			@include with-flex(
				$direction: column,
				$content: flex-start,
				$items: flex-start
			);

			img {
				max-height: 30px;
			}

			.leadVoucher-title {
				font-style: normal;
				font-weight: 700;
				font-size: 34px;
				line-height: 44px;
				letter-spacing: -0.015em;
				margin-top: 1rem;
				color: $primary;
			}

			.leadVoucher-text {
				margin-top: 1rem;
				font-style: normal;
				font-weight: 400;
				font-size: 24.9691px;
				line-height: 46px;
				letter-spacing: -0.015em;
			}

			.link {
				margin-top: 1.5rem;
				font-style: normal;
				font-weight: 700;
				font-size: 23.5946px;
				line-height: 44px;
				letter-spacing: -0.015em;
				text-decoration: none;
				color: $primary;
				@include with-flex($direction: row, $content: center, $items: center);
				img {
					margin-right: 15px;
				}
			}
		}
	}

	.landing-page__leadrMall {
		margin-top: 4em;
		width: 100%;
		padding: 20px;
		display: grid;
		grid-gap: 50px;
		position: relative;

		grid-template-areas: 'one' 'two';

		.orange-vector {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 1;
			display: none;
		}

		.leadrMall-img {
			position: relative;
			z-index: 2;
			img {
				max-width: 100%;
				border-radius: 30px;
			}
			grid-area: one;
		}

		.leadrMall-data {
			img {
				max-height: 30px;
			}

			.leadrMall-title {
				margin-top: 1rem;
				font-style: normal;
				font-weight: 400;
				font-size: 44px;
				line-height: 60px;
				letter-spacing: -0.015em;
			}

			.leadrMall-text {
				font-style: normal;
				font-weight: 400;
				font-size: 24px;
				line-height: 44px;
				letter-spacing: -0.015em;
				color: rgba(0, 0, 0, 0.8);
			}
		}
	}

	.landing-page__pay4u {
		margin-top: 4em;
		width: 100%;
		padding: 20px 20px 80px;

		display: grid;
		gap: 50px;

		.pay4u-img {
			max-width: 100%;
			border-radius: 30px;
		}

		.pay4you-text {
			margin-top: 1rem;
			font-style: normal;
			font-weight: 400;
			font-size: 35px;
			line-height: 55px;
			letter-spacing: -0.015em;

			.pay4u-bold {
				font-weight: 600;
			}
		}
	}

	.landing-page__paylink {
		margin-top: 4em;
		width: 100%;
		padding: 20px;

		display: grid;
		gap: 50px;
		grid-template-areas: 'one' 'two';

		.paylink-img {
			max-width: 100%;
			border-radius: 30px;
			grid-area: one;
		}

		.paylink-data {
			grid-area: two;

			.paylink-text {
				margin-top: 1rem;
				font-style: normal;
				font-weight: 400;
				font-size: 35px;
				line-height: 55px;
				letter-spacing: -0.015em;

				.paylink-bold {
					font-weight: 600;
				}
			}
		}
	}

	.landing-page__virtual-terminal {
		margin-top: 4em;
		width: 100%;
		padding: 20px;

		display: grid;
		gap: 80px;
		grid-template-areas: 'one' 'two';

		.virtual-terminal-img {
			max-width: 100%;
			border-radius: 30px;
			grid-area: one;
		}

		.virtual-terminal-data {
			grid-area: two;

			@include with-flex(
				$direction: column,
				$items: flex-start,
				$content: center
			);

			.virtual-terminal-title {
				font-style: normal;
				font-weight: 700;
				font-size: 44px;
				line-height: 60px;
				letter-spacing: -0.015em;
				color: $primary;
				margin: 1.5rem 0;
			}

			.virtual-terminal-text {
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 38px;
				letter-spacing: -0.015em;
			}

			.virtual-terminal-link {
				margin-top: 1.5rem;
				font-style: normal;
				font-weight: 700;
				font-size: 18px;
				line-height: 30px;
				color: $primary;
				letter-spacing: -0.015em;
				text-decoration: none;
				@include with-flex($direction: row, $content: center, $items: center);
				img {
					margin-right: 15px;
				}
			}
		}
	}

	.landing-page__qrcode {
		margin-top: 4em;
		width: 100%;
		padding: 20px;

		display: grid;
		gap: 80px;
		grid-template-areas: 'one' 'two';

		.qrcode-img {
			max-width: 100%;
			border-radius: 30px;
			grid-area: one;
		}

		.qrcode-data {
			grid-area: two;

			@include with-flex(
				$direction: column,
				$items: flex-start,
				$content: center
			);

			.qrcode-title {
				font-style: normal;
				font-weight: 700;
				font-size: 44px;
				line-height: 60px;
				letter-spacing: -0.015em;
				color: $primary;
				margin: 1.5rem 0;
			}

			.qrcode-text {
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 38px;
				letter-spacing: -0.015em;
			}

			.qrcode-link {
				margin-top: 1.5rem;
				font-style: normal;
				font-weight: 700;
				font-size: 18px;
				line-height: 30px;
				color: $primary;
				letter-spacing: -0.015em;
				text-decoration: none;
				@include with-flex($direction: row, $content: center, $items: center);
				img {
					margin-right: 15px;
				}
			}
		}
	}

	.landing-page__integration {
		margin-top: 4em;
		width: 100%;
		padding: 20px;

		display: grid;
		gap: 80px;
		grid-template-areas: 'one' 'two';

		.integrate-img {
			max-width: 100%;
			border-radius: 30px;
			grid-area: one;
		}

		.integration-data {
			grid-area: two;

			.integration-title {
				font-style: normal;
				font-weight: 700;
				font-size: 52px;
				line-height: 64px;
				color: $primary;
				letter-spacing: -0.015em;
				margin-bottom: 1.2rem;
			}

			.integration-text {
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 38px;
				letter-spacing: -0.015em;
				margin-bottom: 1.2rem;
			}

			.integration-subhead {
				font-style: normal;
				font-weight: 700;
				font-size: 30px;
				line-height: 42px;
				letter-spacing: -0.02em;
				color: $primary;
			}

			.integration-link {
				margin-top: 1.5rem;
				font-style: normal;
				font-weight: 700;
				font-size: 18px;
				line-height: 30px;
				color: $primary;
				letter-spacing: -0.015em;
				text-decoration: none;
				color: $primary;
				@include with-flex($direction: row, $content: flex-start, $items: center);
				img {
					margin-right: 15px;
				}
			}
		}
	}

	.landing-page__join {
		margin-top: 4em;
		width: 100%;
		padding: 50px 20px;
		display: grid;
		gap: 50px;
		grid-template-areas: 'one' 'two';
		background-color: rgba(15, 173, 190, 0.12);

		.join-data {
			width: 100%;
			grid-area: one;

			.join-text {
				font-style: normal;
				font-weight: 400;
				font-size: 25.04px;
				line-height: 34px;
				letter-spacing: -0.015em;

				color: #2b2b2b;
			}

			.join-title {
				font-style: normal;
				font-weight: 700;
				font-size: 40px;
				line-height: 56px;
				color: $primary;
				letter-spacing: -0.015em;
			}
		}

		.join-btn {
			width: 100%;
			grid-area: two;
		}
	}
	.landing-page__send-money {
		background-color: $primary;
		padding: 40px 20px;
		background-image: url('../assets/images/world.svg');
		background-size: cover;
		background-repeat: no-repeat;

		.send-money-text {
			color: $white;
			font-style: normal;
			font-weight: 400;
			font-size: 45px;
			line-height: 60px;
			letter-spacing: -0.015em;
			width: 966px;
			max-width: 100%;
			margin: 2.5rem 0;
		}
	}

	@include small-breakpoint {
		.landing-page__intro {
			grid-template-areas: 'one two';
		}
		.landing-page__what-we-do {
			.what-we-do-grid {
				grid-template-columns: repeat(2, 1fr);

				.what-we-do-grid__img {
					// height: 100%;
				}
				.what-we-do-grid__data {
					grid-template-columns: repeat(2, 1fr);
				}
			}
		}

		.landing-page__pay4u {
			grid-template-columns: repeat(2, 1fr);
		}

		.landing-page__leadVoucher {
			.landing-page__leadvoucher-banner {
				width: 418.15px;
				height: 394.19px;
			}
		}

		.landing-page__why-leadremit {
			.why-leadremit-grid {
				.why-leadremit-grid-img {
					width: 418px;
					img {
						width: 100%;
						height: 394px;
						object-fit: cover;
					}
				}
			}
		}

		.landing-page__paylink {
			grid-template-areas: 'two one';
			.paylink-img {
				max-width: none;
				width: 350.15px;
				height: 394.74px;
				object-fit: cover;
			}
		}

		.landing-page__leadrMall {
			.leadrMall-img {
				width: 418.15px;
				img {
					max-width: none;
					width: 100%;
					height: 394px;
				}
			}
		}

		.landing-page__virtual-terminal {
			grid-template-areas: 'one two';
			.virtual-terminal-img {
				max-width: none;
				width: 345px;
				object-fit: cover;
			}
		}

		.landing-page__qrcode {
			grid-template-areas: 'two one';
			.qrcode-img {
				max-width: none;
				width: 345px;
				object-fit: cover;
			}
		}

		.landing-page__integration {
			grid-template-areas: 'two one';

			.integrate-img {
				display: none;
			}
		}
	}

	@include large-breakpoint {
		margin: 150px auto 0;
		.landing-page__intro {
			grid-template-columns: 45% 1fr;

			padding: 100px 100px;
			.landing-page__intro-img {
				display: block;
				img {
					width: unset;
				}
			}
		}

		.landing-page__what-we-do {
			padding: 100px 100px;
			.what-we-do-grid {
				grid-gap: 100px;
				grid-template-columns: 651px 1fr;

				.what-we-do-grid__img {
					width: 100%;
					height: 617px;
				}
			}
		}

		.landing-page__why-leadremit {
			padding: 100px 100px;

			.why-leadremit-grid {
				grid-gap: 100px;
				grid-template-areas: 'two one';

				.why-leadremit-grid-img {
					width: 441px;
					img {
						width: 100%;
						height: 525px;
						object-fit: cover;
					}
				}
			}
		}

		.landing-page__leadVoucher {
			padding: 100px 100px;
			grid-template-columns: repeat(2, 1fr);

			.blue-vector {
				display: block;
			}

			.landing-page__leadvoucher-banner {
				width: 618.15px;
				height: 594.19px;
			}

			.landing-page__leadvoucher-data {
				@include with-flex(
					$direction: column,
					$content: center,
					$items: flex-start
				);

				img {
					max-height: 40px;
				}

				.leadVoucher-title {
					font-size: 43.0963px;
					line-height: 58px;
				}
			}
		}

		.landing-page__leadrMall {
			padding: 100px 100px;
			grid-gap: 80px;
			grid-template-areas: 'two one';

			.leadrMall-img {
				width: 645px;
				img {
					max-width: none;
					width: 100%;
					height: 620px;
				}
			}
			.orange-vector {
				display: block;
			}
			.leadrMall-data {
				@include with-flex($direction: column);
				img {
					max-height: 45px;
				}

				.leadrMall-title {
					font-size: 52.04px;
					line-height: 70px;
				}
			}
		}

		.landing-page__pay4u {
			padding: 100px 100px;

			.pay4u-img {
				max-width: none;
				width: 643px;
				height: 526px;
				object-fit: cover;
			}

			.pay4u-data {
				@include with-flex($direction: column);
			}
			.pay4you-text {
				font-size: 72.6908px;
				line-height: 96px;
			}
		}

		.landing-page__send-money {
			height: 800px;
			@include with-flex($direction: column);
		}

		.landing-page__paylink {
			padding: 100px 100px;

			.paylink-img {
				max-width: none;
				width: 600px;
				height: 576.74px;
				object-fit: cover;
			}
			.paylink-data {
				.paylink-text {
					font-size: 72.6908px;
					line-height: 96px;
				}
			}
		}

		.landing-page__virtual-terminal {
			padding: 100px 100px;
			.virtual-terminal-img {
				max-width: none;
				width: 545px;
				height: 575px;
				object-fit: cover;
			}
			.virtual-terminal-data {
				.virtual-terminal-title {
					font-size: 52.04px;
					line-height: 70px;
				}

				.virtual-terminal-text {
					font-size: 24.3457px;
					line-height: 45px;
				}

				.virtual-terminal-link {
					font-size: 23.9345px;
					line-height: 45px;
				}
			}
		}

		.landing-page__qrcode {
			padding: 100px 100px;
			.qrcode-img {
				max-width: none;
				width: 645px;
				height: 620px;
				object-fit: cover;
			}
			.qrcode-data {
				.qrcode-title {
					font-size: 52.04px;
					line-height: 70px;
				}

				.qrcode-text {
					font-size: 24.3457px;
					line-height: 45px;
				}

				.qrcode-link {
					font-size: 23.9345px;
					line-height: 45px;
				}
			}
		}

		.landing-page__integration {
			padding: 100px 100px;
			.integrate-img {
				display: block;
				max-width: none;
				width: 700px;
				height: 700px;
				object-fit: cover;
			}
			.integration-data {
				.integration-title {
					font-size: 62.8931px;
					line-height: 74px;
				}

				.integration-text {
					font-size: 24.3457px;
					line-height: 45px;
				}

				.integration-link {
					font-size: 23.9345px;
					line-height: 45px;
				}
				.integration-subhead {
					font-size: 38.1963px;
					line-height: 49px;
				}
			}
		}

		.landing-page__send-money {
			padding: 100px 100px 100px;

			.send-money-text {
				font-size: 72.6908px;
				line-height: 96px;
			}
		}

		.landing-page__join {
			padding: 80px 100px;
			grid-template-areas: 'one two';

			.join-btn,
			.join-data {
				@include with-flex(
					$direction: column,
					$content: center,
					$items: flex-start
				);

				.join-title {
					font-size: 62.6908px;
					line-height: 86px;

					width: 80%;
				}
			}
		}
	}
}
