.nav-sm {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 20px;
	z-index: 5;
	background-color: $white;
	@include with-flex($direction: column, $content: flex-start, $items: center);

	.nav-sm__close {
		width: 100%;
		color: $primary;
    margin-bottom: 1rem;
    cursor: pointer;

		@include with-flex($content: flex-end, $items: center);
	}
  
  .nav-sm__item {
    margin-bottom: 15px;
    width: 200px;
  }
}
